import React from "react";
import colors from "../colors";

const CTA = ({ ctaLink, ctaText, siteSection, onClick, className=null, style={}}) => {

  // If CTA is used for internal link (#section-id)
  // Then don't open in a new window
  let newWindow = false;
  if (ctaLink && ctaLink.indexOf("http") >= 0) {
    newWindow = true;
  }

  const props = {
    href: ctaLink,
    className: `ui button ${style.ctaButton} ${className}`,
    style: {
      ...style,
      backgroundColor: colors[siteSection].cta,
      color: colors[siteSection].ctaText,
    },
    target: newWindow ? "_blank" : "",
    rel: "noopener noreferrer"
  };

  if (onClick) {
    props.href = undefined;
    props.onClick = onClick;

    return (
      <button {...props}>
        {ctaText}
      </button>
    )
  }
  
  return (
    <a {...props}>
      {ctaText}
    </a>
  );
};

export default CTA;
