import React, {  useState } from "react";
import {Container, Grid, Icon, List, Form, Checkbox, Dropdown} from "semantic-ui-react";
import scrollTo from 'gatsby-plugin-smoothscroll';
import "../index.css";
import SiteFooter from "../../../components/site-footer/site-footer"
import CookieConsentModal from '../../../components/cookie-consent-modal/cookie-consent-modal';
import SEO from "../../../components/seo/seo";
import logo from "../../../images/rocksteady-logo.png";
import { Modal } from 'semantic-ui-react';
import styles from "../../../components/contact-form-schools/contact-form-schools.module.css";
import CTA from "../../../components/cta";
import {navigate} from "gatsby";
import { COUNTY_OPTIONS } from "../../../constants";

const BAND_LEADER_BENEFITS = Object.freeze([
  'Full time salary',
  '12 weeks paid holiday',
  'Fuel Allowance',
  'Full training and ongoing support',
  '24/7 online GP service',
  'All staff jams and inspiring away-days',
  'Opportunity to make a positive impact through our Foundation',
  'Retailer discounts'
]);

const Page = () => {

  const renderBenefitsList = (benefitList) => benefitList.map((benefit) => (
      <List.Item key={benefit}>
        <Icon name='check' className="blue-suede-shoes" />
        <List.Content>{benefit}</List.Content>
      </List.Item>
  ));
  const [selectedCounty1, selectCounty1Options] = useState(undefined);
  const [selectedCounty2, selectCounty2Options] = useState(undefined);
  const [selectedCounty3, selectCounty3Options] = useState(undefined);

    const handleSelectCounty1Change = (e, { value }) => {
        selectCounty1Options(value);
    }

    const handleSelectCounty2Change = (e, { value }) => {
        selectCounty2Options(value);
    }

    const handleSelectCounty3Change = (e, { value }) => {
        selectCounty3Options(value);
    }
  const [open, setOpen] = React.useState(false)
  const NACountyOption = [{"text": "N/A", "value": "N/A",  "key": "N/A"}]
  const AllCountyOptions = [{"text": "I want to hear about jobs from all counties", "value": "I want to hear about jobs from all counties",  "key": "I want to hear about jobs from all counties"}]

    const submitForm = (e) => {
        e.preventDefault()
        if(!document.querySelector(':checked')) {
            document.getElementsByClassName(' checkbox-required')[0].style.color="#FF0000";
            return false;
        }
        document.getElementsByClassName('form')[0].submit();
        setTimeout(() => {
            setOpen(false)
        }, 1000);
    };
    const scrollToTop = () => scrollTo('#page-container');


  return (
      <Container id="page-container">
          <SEO
              title="Join our musician talent pool | Rocksteady Music School"
              keywords={[`rocksteady`]}
              description="Rocksteady is growing and we’re looking for musicians who want to make music their career. Join our talent pool and we’ll contact you when a vacancy comes up in your area."
          />
          <div className="sticky-header talent-header">
              <button onClick={scrollToTop}>
                  <img
                      src={logo}
                      className="header-logo"
                      alt="Rocksteady Music School"
                  />
              </button>
              <div className="anchor-links">
                  {/*<a onClick={() => setOpen(true)}>*/}
                  <a href="https://rocksteadymusicschool.us8.list-manage.com/subscribe?u=faf1a0e2ff9154e098f061c83&id=7f7cac586e" target="_blank" rel="noreferrer">
                      <button>
                          Register for Job Alerts
                      </button>
                  </a>
                  <a href="https://boards.greenhouse.io/rocksteadymusicschool/jobs/6548200002" target="_blank" rel="noreferrer">
                      <button>
                          Submit Talent Pool Application
                      </button>
                  </a>
                  <button onClick={()=>{navigate("/careers/")}}>Working at Rocksteady</button>
              </div>
          </div>
          <Modal
              onClose={() => setOpen(false)}
              onOpen={() => setOpen(true)}
              open={open}
          >
              <div className={styles.contentAreaInnerWrapper}>
                  <h2>Sign up for Rocksteady music teacher job alerts</h2>
                  <p>Please enter your details and we will send you job alerts when vacancies come up for the area(s) you specify. Alerts will be sent to the email address you provide below and we may call you if you provide your phone number.</p>
                  <p>* indicates required</p>
                  <Form target='_blank' onSubmit={submitForm} action={'https://rocksteadymusicschool.us8.list-manage.com/subscribe/post?u=faf1a0e2ff9154e098f061c83&id=7f7cac586e&v_id=9&f_id=0056c2e1f0'} method="POST" rel="noopener noreferrer">
                      <Form.Group widths="equal">
                          <Form.Field>
                              <label htmlFor="first-name">First Name*</label>
                              <input name="FNAME" id="mce-FNAME" aria-label="First Name" placeholder="First Name" required />
                          </Form.Field>
                          <Form.Field>
                              <label htmlFor="last-name">Last Name*</label>
                              <input name="LNAME" id="last-name" aria-label="Last Name" placeholder="Last Name" required />
                          </Form.Field>
                      </Form.Group>
                      <Form.Group widths="equal">
                          <Form.Field>
                              <label htmlFor="email">Email*</label>
                              <input name="EMAIL" id="email" aria-label="Email" placeholder="Email" required type="email" />
                          </Form.Field>
                          <Form.Field>
                              <label htmlFor="phone_number">Phone Number</label>
                              <input name="PHONE" id="phone_number" aria-label="Phone Number" placeholder="Phone Number" />
                          </Form.Field>
                      </Form.Group>
                      <Form.Group widths="equal">
                          <Form.Field>
                              <label htmlFor="postcode">Postcode*</label>
                              <input name="MMERGE8" id="postcode" aria-label="Postcode" required placeholder="Postcode" />
                          </Form.Field>
                      </Form.Group>
                      <Form.Group widths="equal">
                          <Form.Field>
                              <label htmlFor="county_state">State the county you wish to hear job alerts from*</label>
                              <Dropdown
                                  fluid
                                  selection
                                  search
                                  placeholder='Select your county'
                                  options={[...AllCountyOptions,...COUNTY_OPTIONS]}
                                  onChange={handleSelectCounty1Change}
                                  value={selectedCounty1}
                                  required
                              />
                              <input type="hidden" name='COUNTY' value={selectedCounty1} />
                          </Form.Field>
                      </Form.Group>
                      <Form.Group widths="equal">
                          <Form.Field>
                              <label htmlFor="country_state2">State other county preference (if applicable)</label>
                              <Dropdown
                                  fluid
                                  selection
                                  search
                                  placeholder='Select your county'
                                  options={[...NACountyOption,...COUNTY_OPTIONS]}
                                  onChange={handleSelectCounty2Change}
                                  value={selectedCounty2}
                              />
                              <input type="hidden"name='COUNTY2' value={selectedCounty2} />
                          </Form.Field>
                      </Form.Group>
                      <Form.Group widths="equal">
                          <Form.Field>
                              <label htmlFor="country_state3">State other county preference (if applicable)</label>
                              <Dropdown
                                  fluid
                                  selection
                                  search
                                  placeholder='Select your county'
                                  options={[...NACountyOption,...COUNTY_OPTIONS]}
                                  onChange={handleSelectCounty3Change}
                                  value={selectedCounty3}
                              />
                              <input type="hidden"name='COUNTY3' value={selectedCounty3} />
                          </Form.Field>
                      </Form.Group>
                      <p>Marketing Permissions</p>
                      <p className='checkbox-required'>Please select all the ways you would like to hear from Rocksteady Music School:</p>
                      <Form.Group widths="equal">
                          <Form.Field>
                              <Checkbox name='gdpr[425]' label='Email'/>&nbsp;&nbsp;
                              <Checkbox name='gdpr[429]' label='Phone' />
                          </Form.Field>
                      </Form.Group>
                      <p>You can unsubscribe at any time by clicking the link in the footer of our emails. For information about our privacy policy, please <a className="talent-link" href = '/privacy-notice/'>visit our website.</a></p>
                      <p>We use Mailchimp as our marketing platform. By clicking below to subscribe, you acknowledge that your information will be transferred to Mailchimp for processing.
                          <a className="talent-link" href="https://mailchimp.com/legal/terms/">
                              &nbsp;Learn more&nbsp;
                          </a>
                          about Mailchimp's privacy practices.</p>
                      <Form.Button
                          type="submit"
                          className='talent-submit-button'
                          style={{
                              backgroundColor: 'black', color: 'white'
                          }}
                          floated="right"
                      >
                          Subscribe <Icon name="right arrow" />
                      </Form.Button>
                  </Form>
              </div>
          </Modal>
          <div className="hero musician-talent-pool">
              <h1>JOIN OUR MUSICIAN TALENT POOL</h1>
              <Grid><Grid.Row columns={2}  only="computer tablet">
                  <Grid.Column textAlign="center">
                      <div>
                          <CTA
                              ctaText="SIGN UP FOR JOB ALERTS"
                              siteSection="careers"
                              className={"talent-button"}
                              ctaLink="https://rocksteadymusicschool.us8.list-manage.com/subscribe?u=faf1a0e2ff9154e098f061c83&id=7f7cac586e"
                              // onClick={() => setOpen(true)}
                          />
                          <CTA
                              ctaText="JOIN OUR TALENT POOL"
                              ctaLink="https://boards.greenhouse.io/rocksteadymusicschool/jobs/6548200002"
                              siteSection="careers"
                              className={"talent-button"}
                          />
                      </div>
                  </Grid.Column>
              </Grid.Row></Grid>
          </div>
          <Grid><Grid.Row columns={1} only="mobile" className='mobile-button'>
              <Grid.Column textAlign="center">
                  <div>
                      <CTA
                          ctaText="SIGN UP FOR JOB ALERTS"
                          siteSection="careers"
                          className={"talent-button"}
                          ctaLink="https://rocksteadymusicschool.us8.list-manage.com/subscribe?u=faf1a0e2ff9154e098f061c83&id=7f7cac586e"
                          // onClick={() => setOpen(true)}
                      />
                      <CTA
                          ctaText="JOIN OUR TALENT POOL"
                          ctaLink="https://boards.greenhouse.io/rocksteadymusicschool/jobs/6548200002"
                          siteSection="careers"
                          className={"talent-button"}
                      />
                  </div>
              </Grid.Column>
          </Grid.Row></Grid>
          <div>
              <div className="section mt-25">
                  <p>Rocksteady Music School is growing and we’re looking for musicians who would like to make music their full time career by leading inspirational band lessons for children. We are currently looking to build our Talent Pool around the country as we continue to launch more jobs throughout the year.</p>
                  <p>A Rocksteady music teacher is the perfect role for someone who is looking for a Monday to Friday music job opportunity that allows them to continue gigging outside of work. As a Rocksteady music teacher you will benefit from full training, a full time salary, 12 weeks paid holiday, Monday to Friday daytime hours and the opportunity to bring the joy and benefits of music to children. Learn more about the role <a onClick={() => scrollTo('#about-the-role')} className="talent-link">below.</a></p>
                  <p>If this sounds ideal to you, you can either
                      <ul>
                          <li>
                              {/*<a className="talent-link" onClick={() => setOpen(true)}>Register for music teacher job alerts</a> – we launch Rocksteady lessons in new areas throughout the year therefore we’ll send you an email when a vacancy comes up close to you, inviting you to submit an application.*/}
                              <a className="talent-link" href="https://rocksteadymusicschool.us8.list-manage.com/subscribe?u=faf1a0e2ff9154e098f061c83&id=7f7cac586e" target="_blank" rel="noreferrer">Register for music teacher job alerts</a> – we launch Rocksteady lessons in new areas throughout the year therefore we’ll send you an email when a vacancy comes up close to you, inviting you to submit an application.
                          </li>
                          <br/>
                          <p>OR</p>
                          <li>
                              <a className="talent-link" href="https://boards.greenhouse.io/rocksteadymusicschool/jobs/6548200002">
                                  Join our talent pool now
                              </a> – fill in a talent pool application form and you’ll make your way through the recruitment process and when a vacancy comes up in your local area, you’re top of the list to contact!
                          </li>
                      </ul>
                  </p>
              </div>
              <Grid><Grid.Row columns={1} only="computer">
                  <Grid.Column textAlign="center">
                      <div>
                          <CTA
                              ctaText="SIGN UP FOR JOB ALERTS"
                              siteSection="careers"
                              className={"talent-button"}
                              // onClick={() => setOpen(true)}
                              ctaLink="https://rocksteadymusicschool.us8.list-manage.com/subscribe?u=faf1a0e2ff9154e098f061c83&id=7f7cac586e"
                          />
                          <CTA
                              ctaText="JOIN OUR TALENT POOL"
                              ctaLink="https://boards.greenhouse.io/rocksteadymusicschool/jobs/6548200002"
                              siteSection="careers"
                              className={"talent-button"}
                          />
                      </div>
                  </Grid.Column>
              </Grid.Row></Grid>
              <div id="about-the-role" className="section mt-25 role-section">
                  <h2>About the role</h2>
                  <p><span className='talent-text'>Job Title:</span> Rock & Pop Music Teacher</p>
                  <p><span className='talent-text'>Salary:</span> Starting from £24,000 per annum (London weighting in addition to salary)</p>
                  <p><span className='talent-text'>Holiday:</span> 12 weeks paid holiday a year</p>
                  <p><span className='talent-text'>Fuel:</span> Fuel package included in addition to salary (Driving Licence and own transport is an essential requirement for the role)</p>
                  <p><span className='talent-text'>Location:</span> You'll teach in multiple schools across a regional area each week. </p>
                  <p><span className='talent-text'>The Role</span>
                      <ul>
                          <li>
                              Deliver progressive and inclusive weekly music lessons in a rock and pop band setting.
                          </li>
                          <li>
                              Teach and inspire children at primary schools in your area
                          </li>
                          <li>
                              Offer children their first opportunity of playing an instrument to develop their confidence, resilience, and wellbeing through performing in a band
                          </li>
                          <li>
                              Be a rock star role model, empowering and supporting children during their first live performances and beyond
                          </li>
                          <li>
                              Complete simple daily administration tasks where required
                          </li>
                          <li>
                              An active role; from loading and setting up band equipment to delivering lessons with energy and enthusiasm
                          </li>
                      </ul>
                  </p>
                  <p><span className='talent-text'>Skills and Experience</span>
                      <ul>
                          <li>
                              Be a multi-instrumentalist - competent on at least 2 of: drums, guitar, bass, keyboard and vocals
                          </li>
                          <li>
                              Be passionate about teaching - experience is preferred, but it's not a requirement (we provide extensive training and support)
                          </li>
                          <li>
                              Ability to work in a way that promotes the safety and wellbeing of children and young people
                          </li>
                          <li>
                              Excellent communication and social skills with both children and adults
                          </li>
                          <li>
                              Be outgoing, confident, and approachable with high energy, interpersonal skills, and enthusiasm
                          </li>
                          <li>
                              Be a strong independent worker with great time management skills
                          </li>
                          <li>
                              Have excellent administration/IT skills and attention to detail
                          </li>
                          <li>
                              High level of integrity and resilience
                          </li>
                          <li>
                              Driving Licence and own transport required
                          </li>
                      </ul>
                  </p>
                  <p>Please note that teaching experience is not required – full training, support and development will be provided.</p>

              </div>

              {/* Video has been taken down temporarily - reinstate when replacement is ready */}
              {/*<div className="section">
                  <div id="career-with-purpose" className="offsetted-anchor"/>
                  <h2>Make music your career</h2>
                  <div id="careers-video-container">
                      <iframe
                          className="careers-video"
                          src={`https://player.vimeo.com/video/[id goes here]`}
                          width="640"
                          height="480"
                          allow="autoplay; fullscreen"
                          frameBorder="0"
                          allowFullScreen
                          title="Rocksteady Music School Careers"
                      ></iframe>
                  </div>
              </div>*/}

              <div className="section background-yellow mt-25">
                  <div id="benefits" className="offsetted-anchor"/>
                  <h2>Benefits of Being a Music Teacher at Rocksteady</h2>
                  <Grid stackable>
                      <Grid.Row columns={2} only="computer tablet">
                          <Grid.Column>
                              <List relaxed>
                                  {renderBenefitsList(
                                      BAND_LEADER_BENEFITS.slice(
                                          0,
                                          -(parseInt(BAND_LEADER_BENEFITS.length / 2, 10))
                                      )
                                  )}
                              </List>
                          </Grid.Column>
                          <Grid.Column>
                              <List relaxed>
                                  {renderBenefitsList(
                                      BAND_LEADER_BENEFITS.slice(
                                          -(parseInt(BAND_LEADER_BENEFITS.length / 2, 10))
                                      )
                                  )}
                              </List>
                          </Grid.Column>
                      </Grid.Row>

                      <Grid.Row columns={2} only="mobile">
                          <Grid.Column>
                              <List relaxed>{renderBenefitsList(BAND_LEADER_BENEFITS)}</List>
                          </Grid.Column>
                      </Grid.Row>
                  </Grid>
              </div>

              <div className="section mt-25">
                  <p>To find out more about music careers at Rocksteady, head to our <a className="talent-link" href = '/careers/'>Careers page</a> to hear from our music teachers.</p>
              </div>
          </div>
          <CookieConsentModal />
          <SiteFooter />
      </Container>

  );
}

export default Page;
